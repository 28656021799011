<section class="story-area">
  <div class="container">
    <br />

    <div class="row">
      <div class="col-sm-2 col-lg-2" style="text-align: left">
        <a class="navbar-brand" routerLink="/"
          ><img src="assets/img/quick.png" alt="Logo" height="80px"
        /></a>
      </div>

      <div class="col-sm-10 col-lg-10"></div>
    </div>

    <br />
    <br />

    <div class="row">
      <div class="col-sm-4 col-lg-4">
        <div class="banner-content">
          <br /><br />
          <br /><br />

          <h1 style="text-align: left">CAFE ET RESTAURANT</h1>
          <h6 style="text-align: left; font-size: 13px; font-weight: normal">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h6>
        </div>
      </div>
      <div class="col-sm-2 col-lg-2"></div>

      <div class="col-sm-6 col-lg-6">
        <div style="width: 545px; height: auto">
          <div class="sorting-menu-session">
            <ul style="margin: 0px; text-align: left">
              <li
                [ngStyle]="
                  code == 0
                    ? { 'background-color': '#F07409' }
                    : { 'background-color': 'black' }
                "
                (click)="goToCheck(0)"
                class="filter"
                style="margin: 0px"
              >
                DEJA INSCRIT ? JE ME CONNECTE
              </li>
              <li
                [ngStyle]="
                  code == 1
                    ? { 'background-color': '#F07409' }
                    : { 'background-color': 'black' }
                "
                (click)="goToCheck(1)"
                class="filter"
                style="margin: 0px"
              >
                NOUVEL ARRIVE ? JE M'INSCRIS
              </li>
            </ul>
          </div>

          <div *ngIf="code == 0" style="background-color: black; height: 100%">
            <div class="container">
              <div class="checkForm">
                <div
                  style="
                    padding-top: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                  "
                >
                  <p *ngIf="responseStatus" style="color: red">
                    {{ responseValue }}
                  </p>

                  <form [formGroup]="loginForm">
                    <div class="form-group">
                      <input
                        type="text"
                        name="username"
                        [formControl]="loginForm.controls['username']"
                        placeholder="Email/Telephone"
                        class="form-control"
                        style="border-radius: 0px"
                      />
                    </div>

                    <div class="form-group">
                      <input
                        type="password"
                        name="password"
                        [formControl]="loginForm.controls['password']"
                        placeholder="Mot de passe"
                        class="form-control"
                        style="border-radius: 0px"
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-lg-6">
                  <!--
                                    <div class="form-check">
                                        <input type="checkbox" value="" id="flexCheckDefault">
                                        &nbsp;
                                        <label class="form-check-label">
                                            Se souvenir de moi
                                        </label>
                                    </div>
                                    -->
                </div>

                <div class="col-sm-6 col-lg-6">
                  <a
                    style="cursor: pointer"
                    (click)="openForgotPasswordModal()"
                  >
                    Mot de passe oublié ?</a
                  >
                </div>
              </div>

              <br />
              <br />

              <div class="text-center">
                <button
                  [disabled]="loginForm.invalid"
                  (click)="onLoginFormSubmit()"
                  type="submit"
                  class="btn cmn-btn-insc"
                  style="color: white"
                >
                  SE CONNECTER
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>

          <div *ngIf="code == 1" style="background-color: black; height: 100%">
            <div class="container">
              <div class="checkForm">
                <div
                  style="
                    padding-top: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                  "
                >
                  <p *ngIf="responseCreateStatus" style="color: red">
                    {{ responseCreateValue }}
                  </p>

                  <form [formGroup]="registerForm">
                    Vous êtes :
                    <input
                      type="radio"
                      [formControl]="registerForm.controls['clientcivilite']"
                      value="M"
                    />
                    &nbsp;
                    <b style="color: white">M.</b>
                    &nbsp;
                    <input
                      type="radio"
                      [formControl]="registerForm.controls['clientcivilite']"
                      value="Mlle"
                    />
                    &nbsp;
                    <b style="color: white">Mlle</b>
                    &nbsp;
                    <input
                      type="radio"
                      [formControl]="registerForm.controls['clientcivilite']"
                      value="Mme"
                    />
                    &nbsp;
                    <b style="color: white">Mme</b>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="clientfirstname"
                            [formControl]="
                              registerForm.controls['clientfirstname']
                            "
                            placeholder="Nom"
                            class="form-control"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="clientlastname"
                            [formControl]="
                              registerForm.controls['clientlastname']
                            "
                            placeholder="Prenom"
                            class="form-control"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="clienttelephone"
                            [formControl]="
                              registerForm.controls['clienttelephone']
                            "
                            placeholder="Telephone"
                            class="form-control"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input
                            type="text"
                            name="clientemail"
                            [formControl]="registerForm.controls['clientemail']"
                            placeholder="Email"
                            class="form-control"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        [type]="typepassword"
                        name="clientpassword"
                        [formControl]="registerForm.controls['clientpassword']"
                        placeholder="Mot de passe"
                        class="form-control"
                        style="border-radius: 0px"
                      />

                      <b style="color: white; font-size: 10px">
                        Minimun 6 caractères, 1 Majuscule et 1 chiffre
                      </b>
                    </div>

                    <div class="form-group">
                      <input
                        [type]="typepassword"
                        name="confirmPassword"
                        [formControl]="registerForm.controls['confirmPassword']"
                        placeholder="Confirmation du mot de passe"
                        class="form-control"
                        style="border-radius: 0px"
                      />
                    </div>

                    <b
                      *ngIf="
                        registerForm.controls.confirmPassword.hasError(
                          'mismatchedPasswords'
                        )
                      "
                      style="color: red; font-size: 10px"
                    >
                      Les deux mots de passes ne correspondent pas
                    </b>
                    <re-captcha
                      class="input-content"
                      [formControl]="registerForm.controls['recaptchaReactive']"
                      (resolved)="resolved($event)"
                      [siteKey]="captchaKey"
                    ></re-captcha>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12" style="text-align: left">
                  <input
                    (click)="changePassword()"
                    type="checkbox"
                    id="flexCheckDefault"
                  />
                  <b> Afficher le mot de passe </b>
                  <br />
                  <input
                    type="checkbox"
                    [formControl]="registerForm.controls['condition']"
                    id="flexCheckDefault"
                  />
                  <b> Accepter les </b>
                  <a
                    style="
                      text-decoration: underline;
                      color: blue;
                      cursor: pointer;
                    "
                    (click)="generationpdf('termesconditions')"
                    >termes & conditions</a
                  >
                </div>
              </div>

              <br />
              <br />

              <div class="text-center">
                <button
                  [disabled]="registerForm.invalid"
                  (click)="onSubscriptionFormSubmit()"
                  type="submit"
                  class="btn cmn-btn-insc"
                  style="color: white"
                >
                  VALIDER
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
