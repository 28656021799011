<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Book A Table</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Book A Table</li>
            </ul>
        </div>
    </div>
</div>

<div class="book-table-area ptb-100">
    <div class="container">
        <div class="book-table-wrap">
            <div class="section-title">
                <h2>Book A Table</h2>
            </div>
            
            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Name">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Address">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Number of table">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Number of person">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input type="date" class="form-control" id="arrive" name="arrive">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea id="your_message" class="form-control" rows="10" placeholder="Write a message"></textarea>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <button type="submit" class="btn cmn-btn">Send Message</button>
                </div>
            </form>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>