<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Checkout</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Checkout</li>
            </ul>
        </div>
    </div>
</div>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="checkout-item">
                    <h2>Customer Details</h2>

                    <div class="checkout-one">
                        <form>
                            <div class="form-group">
                                <label>First Name:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Last Name:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Address:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>City:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Postal Code:</label>
                                <input type="text" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Country:</label>
                                <input type="text" class="form-control">
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="checkout-item">
                    <h2>Payment Methods</h2>

                    <div class="checkout-two">
                        <h3>Direct Bank Transfer</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat ab minima, facere excepturi, adipisci unde, mollitia aperiam ea laboriosam consectetur numquam odio obcaecati quas doloribus quam architecto consequuntur quaerat quos.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum quidem omnis debitis iusto optio. Necessitatibus aliquid soluta cum consectetur atque ipsum corporis non..</p>

                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                            <label class="form-check-label" for="defaultCheck1">
                                <span>I have read and agree to the websites <a href="#">Terms and Conditions</a></span>
                            </label>
                        </div>

                        <div class="text-center">
                            <span>or</span>
                        </div>

                        <ul>
                            <li><a target="_blank" href="#"><img src="assets/img/1.png" alt="Payment"></a></li>
                            <li><a target="_blank" href="#"><img src="assets/img/2.png" alt="Payment"></a></li>
                            <li><a target="_blank" href="#"><img src="assets/img/3.png" alt="Payment"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="text-center">
            <button type="submit" class="btn cmn-btn">Send Details</button>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>