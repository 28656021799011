export const environment = {
  production: true,
  apiEndpoint: "https://ms-quickresto.yolin.ci",
  ipMS: "https://ms-quickresto.yolin.ci/",

  ipGtwMS: "https://crossroad-africa.net/",

  pathYolin: "https://quickresto.yolin.ci/",

  apiCaptcha: "https://ms-quickresto.yolin.ci/captcha/",

  keylocalstorage: "YolinFrontCurentuser",

  urlGateway: "https://crossroad-africa.net/order",

  urlAppMobile: "https://quickresto.yolin.ci/assets/mobileapp/app-Yolin-prod.apk",

  urlCarteRestau: "https://quickresto.yolin.ci/carte",

  yolinEmail: "infos@yolintraiteur.com",

  operationToken: "P5wB1FGI-vH6h-0IsA-xzXi-TqSDvOGbFNm9",

  prefixeCarte: "CA-",

  prefixeAbn: "ABN-",

  prefixeAttributionCarte: "ATT-",

  captchaKey: "6LcIZEgqAAAAANSBfrjRUrL89jJbBtleZpLhOA1b"
};
