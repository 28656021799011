<div class="contact-location-area" style="background-color: white">
  <div class="delete-account-container">
    <h5>SUPPRESSION DE COMPTE</h5>

    <hr style="color: black; font-weight: bold" />

    <p style="font-style: italic; color: #000">
      Nous sommes triste de vous voir partir
    </p>

    <p style="color: #000">
      La suppression de votre compte entrainera la suppression de toutes vos
      données de la plateforme Quick Resto
    </p>

    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="email" class="form-control" formControlName="email" />
      </div>

      <div style="margin-top: 10px" class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Mot de passe"
          formControlName="password"
        />

        <div
          *ngIf="erreursForm['password']"
          style="color: red; font-weight: bold"
        >
          {{ erreursForm["password"] }}
        </div>
      </div>

      <div style="margin-top: 10px">
        <button
          type="submit"
          class="btn cmn-btn-insc"
          style="color: white; width: 100%"
          [ngStyle]="{
            backgroundColor: userForm.invalid || isSubmitting ? 'gray' : ''
          }"
          [disabled]="userForm.invalid || isSubmitting"
        >
          Poursuivre la suppression du compte {{ isSubmitting ? "..." : "" }}
        </button>
      </div>
    </form>
  </div>
</div>
