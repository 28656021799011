<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-two">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more blog-details-more">
                        <h3>Recent Post</h3>

                        <ul>
                            <li><a routerLink="/blog-details">How to Make Egg Nod... <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog-details">Be A Chef <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog-details">Cooking Master <i class='bx bx-plus'></i></a></li>
                        </ul>
                    </div>

                    <div class="services-details-more blog-details-more">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Cooking <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog">Street Food <i class='bx bx-plus'></i></a></li>
                            <li><a routerLink="/blog">Fast Food <i class='bx bx-plus'></i></a></li>
                        </ul>
                    </div>

                    <div class="blog-details-tags">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog">Pasta</a></li>
                            <li><a routerLink="/blog">Home Made</a></li>
                            <li><a routerLink="/blog">Food</a></li>
                            <li><a routerLink="/blog">Street</a></li>
                            <li><a routerLink="/blog">Yammy Food</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <h2>How to Make Pasta</h2>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. For vegetables and fruits, this means that they have been recently harvested and treated properly postharvest; for meat, it has recently been slaughtered and butchered; for fish.</p>
                        <img src="assets/img/blog-details/2.jpg" alt="Blog">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</p>
                        <p class="service-details-p">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                        <div class="blog-details-nav">
                            <ul>
                                <li><a routerLink="/blog-details">Previous</a></li>
                                <li><a routerLink="/blog-details">Next</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>