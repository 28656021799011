<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Categories</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Categories</li>
            </ul>
        </div>
    </div>
</div>

<div class="feature-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature1.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature1.png" alt="Feature"></li>
                            <li><span>Fast Food</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature2.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature2.png" alt="Feature"></li>
                            <li><span>Hot Platter</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature3.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                            <li><span>Dessert</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature4.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                            <li><span>Asian Food</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature5.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                            <li><span>Desi Food</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/feature6.jpg" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <li><img src="assets/img/home-one/feature3.png" alt="Feature"></li>
                            <li><span>Chinese Food</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>