<app-navbar-style-one></app-navbar-style-one>
<div class="page-title-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-3"></div>

      <div class="col-lg-6">
        <div class="page-title-item">
          <h2>MON ESPACE PERSONNEL</h2>
        </div>
      </div>

      <div class="col-lg-3"></div>
    </div>
  </div>
</div>

<div class="services-details-area pt-100 pb-70" style="background-color: white">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="services-details-item">
          <div class="services-details-more">
            <!--
                         <h3>INFORMATION</h3>
                       -->
            <ul>
              <li *ngFor="let link of links">
                <a
                  [ngClass]="{ 'li-selected': link.index === index }"
                  (click)="choiceItems(link.index)"
                  >{{ link.name | titlecase }}</a
                >
              </li>
              <!--
                                <li><a>Ma Carte </a></li>

                            <li><a> Abonnements</a></li>
                            <li><a> Mes Adresses</a></li>
                            <li><a> Mes Commandes</a></li>
                            <li><a>Mes Notifications </a></li>
                            -->
            </ul>

            <div class="banner-btn-wrap">
              <a class="cmn-btn" style="color: white; font-weight: bold"
                >DECONNEXION</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <ng-container *ngIf="index == 1">
          <app-profil></app-profil>
        </ng-container>

        <ng-container *ngIf="index == 2">
          <app-card-relaod></app-card-relaod>
        </ng-container>

        <ng-container *ngIf="index == 3">
          <app-card-secret></app-card-secret>
        </ng-container>

        <ng-container *ngIf="index == 4">
          <app-adresses></app-adresses>
        </ng-container>
        <ng-container *ngIf="index == 5">
          <app-commandes></app-commandes>
        </ng-container>
        <ng-container *ngIf="index == 6">
          <app-commandes></app-commandes>
        </ng-container>
        <ng-container *ngIf="index == 7">
          <app-delete-account></app-delete-account>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<app-footer-style-two></app-footer-style-two>
