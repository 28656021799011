<div *ngIf="responseReturn && !checkMomo" class="location-item"
    style="text-align: center; color: black; padding: 10px;">

    <p style=" margin-bottom: 0px;">Merci d'avoir commandé chez</p>
    <p style="font-weight: bold; margin-bottom: 0px;"> Quick resto.</p>
    <p style="margin-bottom: 0px;"> Votre commande est en cours de préparation
    </p>
    <img src="assets/img/commandeTerminer.png" alt="Location">

    <div style="padding: 15px ; font-weight: normal ; text-align: center ; border: 1px dashed #f07409 ">
        <p style=" margin-bottom: 0px;">Numéro de commande</p>
        <p style="font-weight: bold; margin-bottom: 0px;"> {{f.clientrefcmd}}</p>
    </div>

    <p>
        Les informations de la commande ont été envoyés à l'adresse
        {{f.clientemail}} . <br>
        Pour toutes informations contactez le service : infos@yolintraiteur.com, +225
        0757969678
    </p>


    <div class="banner-btn-wrap">
        <a (click)="closeNotif()" class="cmn-btn" style="color: white; font-weight: bold;">Terminer</a>
    </div>

</div>


<div *ngIf="!responseReturn && !checkMomo" class="location-item"
    style="text-align: center; color: black; padding: 10px;">

    <p style=" margin-bottom: 0px;">Traitement en cours...</p>

    <img src="assets/img/quick.png" alt="Logo" height="80px">


</div>


<div *ngIf="checkMomo" class="location-item" style="text-align: center; color: black; padding: 10px;">
    <p style=" margin-bottom: 0px;">Traitement en cours...</p>
    <img src="assets/img/quick.png" alt="Logo" height="80px">
</div>


<div *ngIf="responseErrorGateway  && checkMomo" class="location-item"
    style="text-align: center; color: black; padding: 10px;">
    <p style=" margin-bottom: 0px;">Erreur Gateway</p>
    <p style=" margin-bottom: 0px;">Un probleme est survenu au niveau de la Gateway... reessayer SVP <br>
        Si le probleme persiste contacter l'administrateur.<br>Merci.</p>

    <div class="banner-btn-wrap">
        <a (click)="closeNotif()" class="cmn-btn" style="color: white; font-weight: bold;">FERMER</a>
    </div>
</div>

<div *ngIf="responseErrorCarte  && !checkMomo" class="location-item"
    style="text-align: center; color: black; padding: 10px;">
    <p style=" margin-bottom: 0px;">Erreur de paiement</p>
    <p style=" margin-bottom: 0px;">Un probleme est survenu lors du paiement par carte... reessayer SVP <br>
        Si le probleme persiste contacter l'administrateur.<br>Merci.</p>

    <div class="banner-btn-wrap">
        <a (click)="closeNotif()" class="cmn-btn" style="color: white; font-weight: bold;">FERMER</a>
    </div>
</div>