<div class="ms-container">
  <app-navbar-style-one></app-navbar-style-one>

  <section class="page-title-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <div class="page-title-item" style="text-align: center">
            <h2>SERVICES</h2>
          </div>
        </div>

        <div class="col-lg-3"></div>
      </div>
    </div>
  </section>

  <section style="margin-top: 2.5rem" class="container ms-section-container">
    <h2 class="ms-section-title">RESTAURATION</h2>

    <div class="ms-section-content">
      <div class="ms-section-img-container">
        <img
          src="https://img-3.journaldesfemmes.fr/sPrB2x9s9eN3GXv_7O8C5GPqEc0=/1240x/smart/1b8817a5b37a468c82cb0dd6ded906b4/ccmcms-jdf/39598024.jpg"
          alt=""
        />
      </div>

      <div class="ms-section-text-container">
        <p>
          A la pause déjeuner, un déjeuner de travail, une petite faim, ou bien
          une grande ocasion, toutes les circonstances sont bonne pour bien
          manger.
        </p>
        <p>
          Notre chef concocte pour vous au quotidien des menus sains, variés et
          gastronomiques d'Afrique et du monde entier. A déguster dans notre
          espace self-service ou l'espace service à table ou tout simplement en
          commandant en ligne.
        </p>
        <p>
          Livraison ou à emporter, vous avez le choix, dans le strict respect
          des règles d'hygiène et des mesures barrières.
        </p>
        <p>Quick Resto, c'est le moment de bien manger.</p>

        <div>Petit déjeuner = 06h30 - 09h.</div>
        <div>Déjeuner = 11h30 - 14h.</div>

        <p>
          Découvrez notre carte gastronomique ici. Entreprises, vous recherchez
          une solution pour vos employés ?
        </p>
        <p>Contactez-nous +225 07 57 96 96 78</p>
      </div>
    </div>
  </section>

  <section
    class="container ms-section-container ms-section-container-row-reverse"
  >
    <h2 class="ms-section-title">TRAITEUR</h2>

    <div class="ms-section-content">
      <div class="ms-section-img-container">
        <img
          src="https://res.cloudinary.com/hv9ssmzrz/image/fetch/c_fill,f_auto,h_600,q_auto,w_800/https://s3-eu-west-1.amazonaws.com/images-ca-1-0-1-eu/tag_photos/original/10831/plat-en-sauce-osso-buco-2535546_1920.jpg"
          alt=""
        />
      </div>

      <div class="ms-section-text-container">
        <p>
          Nous accompagnons tous vos évènements en entreprise : coctails, pause
          déjeuner, pause-café, atelier / séminaires, déjeuner / petit déjeuner
          de presse, célébration particulière...
        </p>
        <p>
          Un mariage, anniversaire, un baptême, une soirée sublimée par des
          menus créatifs et savoureux mêlant les parfums de l'Afrique et du
          monde.
        </p>
        <p>
          Quick Resto vous acceille et vous offre un service hight value avec
          unpersonnel de qualité et expérimenté :
        </p>

        <p>Traiteur</p>
        <p>Privatisation salle + trateur</p>
        <p>Privatisation salle uniquement</p>

        <p>Nos prestations incluent :</p>

        <p>Mise à disposition de la salle</p>
        <p>Mise à disposition de système sonore</p>
        <p>Décoration sur demande</p>
        <p>Mise en place couverts, nappes</p>
        <p>Mise à disposition de personnel de service</p>

        <p>
          Pour plus d'informations ou demander un devis :
          <a href="mailto:infos@yolintraiteur.com">infos@yolintraiteur.com</a>
        </p>

        <p>Quick Resto, c'est le moment de bien manger.</p>

        <div>Petit déjeuner = 06h30 - 09h.</div>
        <div>Déjeuner = 11h30 - 14h.</div>

        <p>
          Découvrez notre carte gastronomique ici. Entreprises, vous recherchez
          une solution pour vos employés ?
        </p>
        <p>Contactez-nous +225 07 57 96 96 78</p>
      </div>
    </div>
  </section>

  <section style="margin-bottom: 2.5rem" class="container ms-section-container">
    <h2 class="ms-section-title">TRAITEUR</h2>

    <div class="ms-section-content">
      <div class="ms-section-img-container">
        <img
          src="https://img-3.journaldesfemmes.fr/sPrB2x9s9eN3GXv_7O8C5GPqEc0=/1240x/smart/1b8817a5b37a468c82cb0dd6ded906b4/ccmcms-jdf/39598024.jpg"
        />
      </div>

      <div class="ms-section-text-container">
        <p>Le lieu privilégié pour voir et être vu!</p>
        <p>
          Un moment de détente entre collègue après une journée harassante ou un
          rdv d'affaires, Le <strong>Loungue Bar de Quick Resto</strong> est le
          lieu rêvé!
        </p>
        <p>
          Venez découvrir nos assortiments de boissons et non alcooliseés et
          divers cocktails créactifs. Déguster les amuse-bouche (salées et
          sucrées), des mini brochettes et des tapas de notre chef
        </p>

        <p>
          Des Soirées à thème = dégustation de vins / café, des concerts
          acoustiques, stand up, soirée football, kizomba ou salsa,
          vernissage... Inscrivez-vous à notre newsletter pouer ne rien rater.
        </p>

        <p>Lundi au Jeudi de 17h30 à 21h00</p>
        <p>Vendredi de 17h30 à 00h</p>
      </div>
    </div>
  </section>
  <app-footer-style-two></app-footer-style-two>
</div>
