<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                        <span>01 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Pasta</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                        <span>02 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Pizza</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Burger</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog4.jpg" alt="Blog"></a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Sandwich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog5.jpg" alt="Blog"></a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Coffee</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/blog6.jpg" alt="Blog"></a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Brief About How to Make Tea</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>