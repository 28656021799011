import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { ClientAuth } from "../models/models";
import { NotifDialogComponent } from "../notif-dialog/notif-dialog.component";
import { ParameterService } from "../parameter.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-delete-account",
  templateUrl: "./delete-account.component.html",
  styles: [
    `
      .delete-account-container {
        max-width: 500px;
      }
    `,
  ],
})
export class DeleteAccountComponent implements OnInit, OnDestroy {
  currentUser: ClientAuth;

  public isSubmitting = false;

  public subscription: Subscription = new Subscription();

  private regex = "((?=.*\\d)(?=.*[A-Z]).{6,20})";

  public userForm: FormGroup = this.formBuilder.group({
    email: [{ value: "", disabled: true }],
    password: ["", [Validators.required, Validators.pattern(this.regex)]],
  });

  public erreursForm: Record<string, string> = {
    password: "",
  };

  public messagesErreur: Record<string, Record<string, string>> = {
    password: {
      required: "Ce champ est requis.",
      pattern: "Minimun 6 caractères, 1 Majuscule et 1 chiffre",
    },
  };

  hide = true;
  public closeResult: string;

  constructor(
    public formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.userForm.statusChanges.subscribe(() => {
        this.changementStatusForm();
      })
    );

    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      this.currentUser = JSON.parse(
        localStorage.getItem("YolinFrontCurentuser")
      );
    } else {
      this.currentUser = JSON.parse(
        sessionStorage.getItem("YolinFrontCurentuser")
      );
    }
    // Verifier si le client est en ligne
    if (this.currentUser) {
      this.userForm = this.formBuilder.group({
        email: [{ value: this.currentUser.client.clientemail, disabled: true }],
        password: ["", [Validators.required, Validators.pattern(this.regex)]],
      });
    } else {
      this.router.navigate(["/"]); //Rediriger l'utilisateur à l'accueil
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async openNotifModal(message) {
    this.modalService
      .open(NotifDialogComponent, {
        size: "xs",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        injector: Injector.create([
          {
            provide: String,
            useValue: message,
          },
        ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
          console.log(result);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `${reason}`;
    }
  }

  public onSubmit() {
    this.isSubmitting = true;

    const formValues = this.userForm.getRawValue();

    const data = {
      username: formValues.email,
      password: formValues.password,
    };

    this.parameterService.deleteAccount(data).subscribe(
      (data) => {
        switch (data.status) {
          case 200: {
            this.openNotifModal("Compte supprimé avec succès ");

            // Supprimer les données user et panier
            //Check RememberMe
            if (
              localStorage.getItem("YolinRememberMe") &&
              JSON.parse(localStorage.getItem("YolinRememberMe"))
            ) {
              localStorage.removeItem("YolinFrontCurentuser");
            } else {
              sessionStorage.removeItem("YolinFrontCurentuser");
            }

            sessionStorage.removeItem("allfood");

            this.router.navigate(["/"]);
            break;
          }
          default: {
            this.openNotifModal(
              "Une erreur s'est produite, veuillez reessayer"
            );
            break;
          }
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.openNotifModal("Une erreur s'est produite, veuillez reessayer");
      }
    );
  }

  private changementStatusForm() {
    if (!this.userForm) {
      return;
    }

    const form = this.userForm;

    for (const field in this.erreursForm) {
      this.erreursForm[field] = "";
      let control: AbstractControl;
      control = form.get(field);

      if (control && control.touched && control.invalid) {
        const messages = this.messagesErreur[field];
        for (const key in control.errors) {
          this.erreursForm[field] += messages[key] + " ";
        }
      }
    }
  }
}
