import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ClientAuth, Clientlogin } from "./models/models";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ParameterService {
  authorization: string = " ";

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.checkSecurity();
  }

  checkSecurity() {
    let u = new ClientAuth();
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      u = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));
    } else {
      u = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));
    }

    if (u != null) {
      this.authorization = u.token;
    }
  }

  downloadpdf(type: string): Observable<HttpResponse<any>> {
    let url = environment.apiEndpoint + "/extra/downloadpdf/" + type;
    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
        "Accept",
        "application/pdf"
      ),
      //.set('content-Type', 'application/json')
      //.set('Authorization', this.authorization)
    });
  }

  getAllWithout(url: any): Observable<HttpResponse<any>> {
    return this.http.get(environment.apiEndpoint + "/" + url, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
        "content-Type",
        "application/json"
      ),
      //.set("Authorization", this.authorization)
    });
  }

  postWithoutKey(url: any, value: any): Observable<HttpResponse<any>> {
    return this.http.post(environment.apiEndpoint + "/" + url, value, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
        "content-Type",
        "application/json"
      ),
    });
  }

  postWithKey(url: any, value: any): Observable<HttpResponse<any>> {
    this.checkSecurity();

    return this.http.post(environment.apiEndpoint + "/" + url, value, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization),
    });
  }

  updateWithKey(url: any, value: any): Observable<HttpResponse<any>> {
    this.checkSecurity();

    return this.http.put(environment.apiEndpoint + "/" + url, value, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization),
    });
  }

  deleteWithKey(url: any): Observable<HttpResponse<any>> {
    this.checkSecurity();
    return this.http.delete(environment.apiEndpoint + "/" + url, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization),
    });
  }

  postCapctha(url: any, value: any): Observable<HttpResponse<any>> {
    this.checkSecurity();

    return this.http.post(environment.apiCaptcha + url, value, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
        "content-Type",
        "application/json"
      ),
    });
  }

  getAllWithKey(url: any): Observable<HttpResponse<any>> {
    this.checkSecurity();

    return this.http.get(environment.apiEndpoint + "/" + url, {
      observe: "response",
      headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" })
        .set("content-Type", "application/json")
        .set("Authorization", this.authorization),
    });
  }

  public LoginClient(value: Clientlogin): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.apiEndpoint + "/" + "yolin/session/login/client",
      JSON.stringify(value),
      {
        observe: "response",
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
          "content-Type",
          "application/json"
        ),
        // .set("Authorization", "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NSIsImV4cCI6MTU2Mzc3NTU2Miwicm9sZXMiOltdfQ.0wZz6wVPPHtCLiwCOg4AvDUTH5Cm_3SPyEMgb0iPsh4")
      }
    );
  }

  convertDateToString(date: string, heure?: boolean): string {
    let dateRecu = new Date(date);
    let localTime = dateRecu.toLocaleTimeString();
    let indexMonth = dateRecu.getMonth() + 1;
    let indexDay = dateRecu.getDate();
    let miliseconds = dateRecu.getMilliseconds();
    // this.logger.trace(currentDate.getMilliseconds);

    if (!heure) {
      // let dateFinale = dateRecu.getFullYear() + '-' +((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + localTime + '.' + miliseconds;
      let dateFinale =
        dateRecu.getFullYear() +
        "-" +
        (indexMonth < 10 ? "0" + indexMonth : indexMonth) +
        "-" +
        (indexDay < 10 ? "0" + indexDay : indexDay);
      const formattedCurrentDate = dateFinale;
      // this.logger.trace('formattedCurrentDate');
      // this.logger.trace(formattedCurrentDate);
      return formattedCurrentDate;
    } else {
      let dateFinale =
        dateRecu.getFullYear() +
        "-" +
        (indexMonth < 10 ? "0" + indexMonth : indexMonth) +
        "-" +
        (indexDay < 10 ? "0" + indexDay : indexDay) +
        " " +
        dateRecu.toLocaleTimeString() +
        "." +
        dateRecu.getMilliseconds();
      const formattedCurrentDate = dateFinale;
      return formattedCurrentDate;
    }
  }

  getCurrentDate(): string {
    let timestampDate = Date.now();

    let currentDate = new Date(timestampDate);
    let localTime = currentDate.toLocaleTimeString();
    let indexMonth = currentDate.getMonth() + 1;
    let indexDay = currentDate.getDate();
    let miliseconds = currentDate.getMilliseconds();
    //this.logger.trace(currentDate.getMilliseconds);

    let dateFinale =
      currentDate.getFullYear() +
      "-" +
      (indexMonth < 10 ? "0" + indexMonth : indexMonth) +
      "-" +
      (indexDay < 10 ? "0" + indexDay : indexDay) +
      " " +
      localTime +
      "." +
      miliseconds;
    const formattedCurrentDate = dateFinale;
    // this.logger.trace('formattedCurrentDate');
    return formattedCurrentDate;
  }

  checkHourFood(heuredebut: string, heurefin: string): boolean {
    //Get today date
    let timestampDate = Date.now();

    let currentDate = new Date(timestampDate);
    let indexMonth = currentDate.getMonth() + 1;
    let indexDay = currentDate.getDate();

    let dateDuJour =
      currentDate.getFullYear() +
      "-" +
      (indexMonth < 10 ? "0" + indexMonth : indexMonth) +
      "-" +
      (indexDay < 10 ? "0" + indexDay : indexDay);

    //this.logger.trace('Hour service: ' , currentDate.getHours() + ':' + currentDate.getMinutes());
    let heuredebutModif = new Date(dateDuJour + " " + heuredebut);
    let heurefinModif = new Date(dateDuJour + " " + heurefin);

    /*this.logger.trace('Date heure debut: ' , heuredebutModif);
    this.logger.trace('Date heure fin: ' , heurefinModif);*/

    // compare
    if (currentDate > heuredebutModif && currentDate < heurefinModif) {
      return false;
    } else {
      return true;
    }
  }

  checkHourCommande(heure: string, minute: string): boolean {
    //Get today date
    let timestampDate = Date.now();

    let currentDate = new Date(timestampDate);
    let indexMonth = currentDate.getMonth() + 1;
    let indexDay = currentDate.getDate();

    let dateDuJour =
      currentDate.getFullYear() +
      "-" +
      (indexMonth < 10 ? "0" + indexMonth : indexMonth) +
      "-" +
      (indexDay < 10 ? "0" + indexDay : indexDay);

    //this.logger.trace('Hour service: ' , currentDate.getHours() + ':' + currentDate.getMinutes());
    let heureModif = new Date(dateDuJour + " " + heure + ":" + minute);

    /*this.logger.trace('Date heure debut: ' , heuredebutModif);
    this.logger.trace('Date heure fin: ' , heurefinModif);*/

    // compare
    if (currentDate > heureModif) {
      return false;
    } else {
      return true;
    }
  }

  public compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    //this.logger.trace('D1', d1);
    //this.logger.trace('D2', d2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  // Add By Christian
  postEmailToResetPassword(
    emailOrPassword: string
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      `${environment.apiEndpoint}/yolin/session/client/request/rinitpsswd/${emailOrPassword}`,
      {
        observe: "response",
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
          "content-Type",
          "application/json"
        ),
      }
    );
  }

  // Add By Christian
  resetPassword(newPassword: string): Observable<HttpResponse<any>> {
    return this.http.put(
      `${environment.apiEndpoint}/yolin/session/client/resetpsswd`,
      JSON.stringify({
        newPassword,
        otp: this.route.snapshot.queryParamMap.get("token"),
      }),
      {
        observe: "response",
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
          "content-Type",
          "application/json"
        ),
      }
    );
  }

  // Add By Christian
  updatePassword(data): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.apiEndpoint}/yolin/session/client/renewpassword`,
      JSON.stringify(data),
      {
        observe: "response",
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
          "content-Type",
          "application/json"
        ),
      }
    );
  }

  // Add By Christian
  deleteAccount(data): Observable<HttpResponse<any>> {
    console.log(data);
    return this.http.post(
      `${environment.apiEndpoint}/yolin/session/client/delete`,
      JSON.stringify(data),
      {
        observe: "response",
        headers: new HttpHeaders({ "Access-Control-Allow-Origin": "*" }).set(
          "content-Type",
          "application/json"
        ),
      }
    );
  }
}
