import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { NavbarStyleOneComponent } from "./components/common/navbar-style-one/navbar-style-one.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { FooterStyleOneComponent } from "./components/common/footer-style-one/footer-style-one.component";
import { FooterStyleTwoComponent } from "./components/common/footer-style-two/footer-style-two.component";
import { NavbarStyleTwoComponent } from "./components/common/navbar-style-two/navbar-style-two.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { CategoriesComponent } from "./components/pages/categories/categories.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { FoodCollectionComponent } from "./components/pages/food-collection/food-collection.component";
import { OnlineOrderComponent } from "./components/pages/online-order/online-order.component";
import { ChefsComponent } from "./components/pages/chefs/chefs.component";
import { BookTableComponent } from "./components/pages/book-table/book-table.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { NavbarStyleThreeComponent } from "./components/common/navbar-style-three/navbar-style-three.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { AccueilComponent } from "./components/pages/restau-tresor/accueil/accueil.component";
import { MenuCarteComponent } from "./components/pages/restau-tresor/menu-carte/menu-carte.component";
import { MenuServiceComponent } from "./components/pages/restau-tresor/menu-service/menu-service.component";
import { MenuContactComponent } from "./components/pages/restau-tresor/menu-contact/menu-contact.component";
import { MenuFaqComponent } from "./components/pages/restau-tresor/menu-faq/menu-faq.component";
import { MenuProposComponent } from "./components/pages/restau-tresor/menu-propos/menu-propos.component";
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FoodDialogComponent } from "./components/pages/restau-tresor/food-dialog/food-dialog.component";
import { NotifDialogComponent } from "./components/pages/restau-tresor/notif-dialog/notif-dialog.component";
import { SessionComponent } from "./components/pages/restau-tresor/session/session.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MenuCartComponent } from "./components/pages/restau-tresor/menu-cart/menu-cart.component";
import { SuccesDialogComponent } from "./components/pages/restau-tresor/succes-dialog/succes-dialog.component";
import { LoadingDialogComponent } from "./components/pages/restau-tresor/loading-dialog/loading-dialog.component";
import { SpaceUserComponent } from "./components/pages/restau-tresor/space-user/space-user.component";
import { CommonModule } from "@angular/common";
import { CommandesComponent } from "./components/pages/restau-tresor/commandes/commandes.component";
import { ListFilterPipe } from "./components/pages/restau-tresor/list-filter.pipe";
import { NotificationComponent } from "./components/pages/restau-tresor/notification/notification.component";
import { AdressesComponent } from "./components/pages/restau-tresor/adresses/adresses.component";
import { ProfilComponent } from "./components/pages/restau-tresor/profil/profil.component";
import { CardRelaodComponent } from "./components/pages/restau-tresor/card-relaod/card-relaod.component";
import { CardSecretComponent } from "./components/pages/restau-tresor/card-secret/card-secret.component";
import { CreditComponent } from "./components/pages/restau-tresor/card-relaod/credit/credit.component";
import { CodeComponent } from "./components/pages/restau-tresor/card-relaod/code/code.component";
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_LANGUAGE,
} from "ng-recaptcha";
import { SecretComponent } from "./secret/secret.component";
import { SecretcodeComponent } from "./components/pages/restau-tresor/card-relaod/secretcode/secretcode.component";
import { FoodShowComponent } from "./components/pages/restau-tresor/food-show/food-show.component";
import { CarteComponent } from "./components/pages/restau-tresor/card-relaod/carte/carte.component";
import { CartelockedComponent } from "./components/pages/restau-tresor/card-relaod/cartelocked/cartelocked.component";
import { NotifOptionComponent } from "./components/pages/restau-tresor/notif-option/notif-option.component";
import { QRCodeModule } from "angularx-qrcode";
import { AddressDialogComponent } from "./components/pages/restau-tresor/adresses/address-dialog/address-dialog.component";
import { AddressDeleteDialogComponent } from "./components/pages/restau-tresor/adresses/address-delete-dialog/address-delete-dialog.component";
import { AddressSelectedDialogComponent } from "./components/pages/restau-tresor/adresses/address-selected-dialog/address-selected-dialog.component";
import { UpdatepasswdComponent } from "./components/pages/restau-tresor/profil/updatepasswd/updatepasswd.component";
import { CoporateAbnComponent } from "./components/pages/restau-tresor/card-secret/coporate-abn/coporate-abn.component";
import { TypAbnComponent } from "./components/pages/restau-tresor/card-secret/typ-abn/typ-abn.component";
import { SubAbnComponent } from "./components/pages/restau-tresor/card-secret/sub-abn/sub-abn.component";
import { LoadAbnComponent } from "./components/pages/restau-tresor/card-secret/load-abn/load-abn.component";
import { MyAbnComponent } from "./components/pages/restau-tresor/card-secret/my-abn/my-abn.component";
import { GestAbnComponent } from "./components/pages/restau-tresor/card-secret/gest-abn/gest-abn.component";
import { FilterFormulePipe } from "./components/pages/restau-tresor/pipe/pipes/filter-formule.pipe";
import { ForgotPasswordComponent } from "./components/pages/restau-tresor/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/pages/restau-tresor/reset-password/reset-password.component";

import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { NotifselectedComponent } from "./components/pages/restau-tresor/card-relaod/notifselected/notifselected.component";
import { DeleteAccountComponent } from "./components/pages/restau-tresor/delete-account/delete-account.component";
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    AccueilComponent,
    MenuCarteComponent,
    MenuContactComponent,
    MenuFaqComponent,
    MenuProposComponent,
    MenuServiceComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    NavbarStyleTwoComponent,
    AboutComponent,
    CategoriesComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    FoodCollectionComponent,
    OnlineOrderComponent,
    ChefsComponent,
    BookTableComponent,
    CartComponent,
    CheckoutComponent,
    ComingSoonComponent,
    FaqComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ErrorComponent,
    ContactComponent,
    NavbarStyleThreeComponent,
    AccueilComponent,
    MenuCarteComponent,
    MenuServiceComponent,
    MenuContactComponent,
    MenuFaqComponent,
    MenuProposComponent,
    FoodDialogComponent,
    NotifDialogComponent,
    SessionComponent,
    MenuCartComponent,
    SuccesDialogComponent,
    LoadingDialogComponent,
    SpaceUserComponent,
    CommandesComponent,
    ListFilterPipe,
    NotificationComponent,
    AdressesComponent,
    ProfilComponent,
    CardRelaodComponent,
    CardSecretComponent,
    CreditComponent,
    CodeComponent,
    SecretComponent,
    SecretcodeComponent,
    FoodShowComponent,
    CarteComponent,
    CartelockedComponent,
    NotifOptionComponent,
    NotifselectedComponent,
    AddressDialogComponent,
    AddressDeleteDialogComponent,
    AddressSelectedDialogComponent,
    UpdatepasswdComponent,
    CoporateAbnComponent,
    TypAbnComponent,
    SubAbnComponent,
    LoadAbnComponent,
    MyAbnComponent,
    GestAbnComponent,
    FilterFormulePipe,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DeleteAccountComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CarouselModule,
    NgbModule,
    QRCodeModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AppRoutingModule,
  ],
  exports: [
    FoodDialogComponent,
    AddressDialogComponent,
    AddressDeleteDialogComponent,
    AddressSelectedDialogComponent,
    NotifDialogComponent,
    NotifOptionComponent,
    NotifselectedComponent,
    FoodShowComponent,
    FooterStyleTwoComponent,
    NavbarStyleOneComponent,
    FilterFormulePipe,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "fr", // use French language
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
